import { TypedResponse, redirect } from "@remix-run/node";
import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

const MESSAGE_BANNER_COOKIE_NAME = "messageBanner";

export function HydrateFallback() {
  return <div>Loading...</div>;
}

//todo: figure out why the ignore hack is needed here but not in internal web app
export default function MessageBanner() {
  const location = useLocation();
  let ignore = false;

  useEffect(() => {
    const element = document.getElementById("message-banner");
    if (!element) {
      throw new Error("Element with id 'message-banner' not found");
    }
    const message = getCookie(MESSAGE_BANNER_COOKIE_NAME);
    if (message) {
      element.innerHTML = message;
      element.style.display = "block";
      if (!ignore) {
        document.cookie = MESSAGE_BANNER_COOKIE_NAME + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
      }
    } else {
      if (!ignore) {
        element.innerHTML = "";
        element.style.display = "none";
      }
    }
    return () => {
      // This hack is because useEffect() was being run twice and the message wasn't displaying because it was gone the second time
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ignore = true;
    };
  }, [location]);
  return <div id="message-banner" style={{ display: "none" }}></div>;
}

export async function redirectWithMessage(url: string, message: string): Promise<TypedResponse<never>> {
  const encodedMessage = encodeURIComponent(message);
  return redirect(url, {
    headers: {
      "Set-Cookie": `${MESSAGE_BANNER_COOKIE_NAME}=${encodedMessage}; Path=/;`,
    },
  });
}

function getCookie(name: string): string | null {
  const fullCookie = `; ${document.cookie}`;
  const parts = fullCookie.split(`; ${name}=`);
  if (parts.length === 2) {
    const secondPart = parts.pop() ?? ""; // ?? "" is a TS workaround
    const value = secondPart.split(";").shift() ?? ""; // ?? "" is a TS workaround
    return decodeURIComponent(value);
  } else {
    return null;
  }
}
